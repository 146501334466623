<template>
  <div class="px-4 pt-5">
    <div class="opacity-50 flex items-center justify-between text-sm mb-1">
      {{ progressMessage }}
      <div>
        {{ progressPercent }}%
      </div>
    </div>
    <div class="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 mb-5">
      <div class="bg-blue-600 h-2.5 rounded-full" :style="`width: ${progressPercent}%`"></div>
    </div>

    <template v-if="userAppStatus === 'CHOICE_LANG'">
      <div class="bg-white px-4 py-2 rounded-xl">
        <a @click="onChangeLanguage(language)" href="javascript:void(0)"
           v-for="(language, langIndex) in languages" :key="langIndex"
           :class="[
            'flex justify-between items-center py-2',
            langIndex === (languages.length - 1) ? '' : 'border-b border-gray-100'
          ]"
        >
          <div class="flex flex-col">
            <div>{{ language.title }}</div>
            <div class="text-xs">{{ language.native_title }}</div>
          </div>
          <div v-if="language.language_code === $user.language_code">
            <CheckCircleIcon class="w-5 text-blue-600" />
          </div>
        </a>
      </div>
      <div class="mt-4">
        <button
            @click="onApplyLang"
            type="button"
            class="flex w-full items-center justify-center rounded-xl bg-blue-600 px-3 py-3 font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            :class="[isFormProcessing ? 'opacity-50 pointer-events-none' : '']"
        >
          <template v-if="isFormProcessing">
            <ButtonSpinner />
          </template>
          {{ $t('choice_lang') }}
        </button>
      </div>
    </template>

    <template v-if="userAppStatus === 'PENDING_TERMS_ACCEPTANCE'">
      <div>
        <div class="bg-white px-4 py-3 rounded-xl">
          <div class="text-2xl font-semibold">{{ $t('accept_terms') }}</div>
          <div class="text-sm mt-2">
            {{ $t('accept_terms_desc') }}
          </div>

          <dd class="mt-4 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            <ul role="list" class="divide-y divide-gray-100 rounded-xl border border-gray-200">
              <li v-for="document in documents" :key="document.id" class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                <div class="flex w-0 flex-1 items-center">
                  <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                  <div class="ml-4 flex min-w-0 flex-1 gap-2">
                    <span class="truncate font-medium">{{ document.title }}</span>
                  </div>
                </div>
                <div class="ml-3 flex-shrink-0">
                  <a :href="document.file" target="_blank" class="font-medium text-blue-600 hover:text-blue-500">{{ $t('view') }}</a>
                </div>
              </li>
            </ul>
          </dd>

          <div class="mt-4 relative flex items-start">
            <div class="flex h-6 items-center">
              <input v-model="termsForm.accept" id="apply_terms" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600" />
            </div>
            <div class="ml-3 text-sm leading-6">
              <label for="apply_terms" class="font-medium text-gray-900">{{ $t('apply_terms') }}</label>
              <p id="comments-description" class="text-sm text-gray-500">{{ $t('apply_terms_desc') }}</p>
            </div>
          </div>
        </div>

        <div class="mt-4">
          <button
            @click="onApplyTerms"
            type="button"
            class="flex w-full items-center justify-center rounded-xl bg-blue-600 px-3 py-3 font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            :class="[isFormProcessing || !termsForm.accept ? 'opacity-50 pointer-events-none' : '']"
          >
            <template v-if="isFormProcessing">
              <ButtonSpinner />
            </template>
            {{ $t('apply_terms') }}
          </button>
        </div>
      </div>
    </template>

    <template v-if="userAppStatus === 'CONTACT_DETAILS_INCOMPLETE'">
      <div>
        <div class="bg-white rounded-xl px-4 pt-3 pb-4">
          <div class="text-3xl font-semibold">{{ $t('fill_contacts') }}</div>

          <div class="mt-4">
            <div>
              <label for="email" class="block text-sm font-medium leading-6 text-gray-900">E-mail</label>
              <div class="mt-2">
                <input
                    v-model="contactForm.email"
                    id="email"
                    type="email"
                    class="block w-full rounded-xl border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                />
                <p v-if="emailError" class="text-red-500 text-sm mt-1">{{ emailError }}</p>
              </div>
            </div>
            <div class="mt-3">
              <PhoneInput v-model="contactForm.phone" />
              <p v-if="phoneError" class="text-red-500 text-sm mt-1">{{ phoneError }}</p>
            </div>
          </div>
        </div>

        <div class="mt-5">
          <button
              @click="updateContacts"
              type="button"
              class="flex w-full items-center justify-center rounded-xl bg-blue-600 px-3 py-3 font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              :class="[isFormProcessing ? 'opacity-50 pointer-events-none' : '']"
          >
            <template v-if="isFormProcessing">
              <ButtonSpinner />
            </template>
            {{ $t('save_contacts') }}
          </button>
        </div>
      </div>
    </template>

    <template v-if="userAppStatus === 'EXCHANGE_NOT_CONNECTED'">
      <div>
        <ConnectExchangeAccount @action-success="onSuccessConnectAccount" />
      </div>
    </template>

    <template v-if="userAppStatus === 'PLAN_NOT_SELECTED'">
      <SelectTariff />
    </template>

    <template v-if="userAppStatus === 'NO_SIGNALS_SELECTED'">
      <div class="pb-10">
        <SelectSignals />
        <div class="mt-5">
          <button
              @click="completeSetup"
              type="button"
              class="flex w-full items-center justify-center rounded-xl bg-blue-600 px-3 py-3 font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              :class="[isFormProcessing ? 'opacity-50 pointer-events-none' : '']"
          >
            <template v-if="isFormProcessing">
              <ButtonSpinner />
            </template>
            {{ $t('complete_setup') }}
          </button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { PaperClipIcon } from "@heroicons/vue/24/outline";
import ButtonSpinner from "@/components/ButtonSpinner.vue";
import PhoneInput from "@/components/form/PhoneInput.vue";
import { validatePhoneNumber } from "@/helpers/validationHelper";
import {CheckCircleIcon} from "@heroicons/vue/20/solid";
import ConnectExchangeAccount from "@/components/exchange/ConnectExchangeAccount.vue";
import SelectTariff from "@/components/tariff/SelectTariff.vue";
import SelectSignals from "@/components/settings/SelectSignals.vue";

export default {
  components: {
    CheckCircleIcon,
    ButtonSpinner,
    PaperClipIcon,
    PhoneInput,
    ConnectExchangeAccount,
    SelectTariff,
    SelectSignals
  },

  data() {
    return {
      isFormProcessing: false,
      userAppStatus: this.$user.app_status,
      termsForm: {
        accept: false,
      },
      contactForm: {
        email: '',
        phone: ''
      },
      emailError: '',
      phoneError: '',

      languages: [],
      documents: [],
    }
  },

  mounted() {
    this.loadLanguages();
    this.loadDocuments();
    this.checkStatus();
  },

  watch: {
    userAppStatus(newStatus) {
      console.log(newStatus);
      this.checkStatus();
    }
  },

  computed: {
    progressPercent() {
      if (this.userAppStatus === 'CHOICE_LANG') {
        return 0;
      }

      if (this.userAppStatus === 'PENDING_TERMS_ACCEPTANCE') {
        return 10;
      }

      if (this.userAppStatus === 'CONTACT_DETAILS_INCOMPLETE') {
        return 20;
      }

      if (this.userAppStatus === 'EXCHANGE_NOT_CONNECTED') {
        return 40;
      }

      if (this.userAppStatus === 'PLAN_NOT_SELECTED') {
        return 60;
      }

      if (this.userAppStatus === 'NO_SIGNALS_SELECTED') {
        return 80;
      }

      return 0;
    },
    progressMessage() {
      if (this.userAppStatus === 'CHOICE_LANG') {
        return this.$t('language');
      }

      if (this.userAppStatus === 'PENDING_TERMS_ACCEPTANCE') {
        return this.$t('accept_terms');
      }

      if (this.userAppStatus === 'CONTACT_DETAILS_INCOMPLETE') {
        return this.$t('fill_contacts');
      }

      if (this.userAppStatus === 'EXCHANGE_NOT_CONNECTED') {
        return this.$t('add_exchange_account');
      }

      if (this.userAppStatus === 'PLAN_NOT_SELECTED') {
        return this.$t('select_tariff');
      }

      if (this.userAppStatus === 'NO_SIGNALS_SELECTED') {
        return this.$t('signals');
      }

      return '';
    }
  },

  methods: {
    checkStatus() {
      this.checkCompleteSetup();
    },

    onChangeLanguage(language) {
      this.$api.post('/user/update-language', {
        language_code: language.language_code
      }).then((result) => {
        console.log('Settings updated.', result);

        this.$i18n.locale = language.language_code;
        this.$user.language_code = language.language_code;
      }).catch(e => {
        alert(e.message);
        this.error = e;
        this.processing = null;
      });
    },

    onApplyLang() {
      this.isFormProcessing = true;

      this.$api.post('/user/apply-lang').then(result => {
        console.log('result', result);
        this.userAppStatus = 'PENDING_TERMS_ACCEPTANCE';
        this.isFormProcessing = false;
      });
    },

    onApplyTerms() {
      this.isFormProcessing = true;

      if (this.termsForm.accept) {
        this.$api.post('/user/apply-terms').then(result => {
          console.log('result', result);
          this.userAppStatus = 'CONTACT_DETAILS_INCOMPLETE';
          this.isFormProcessing = false;
        });
      }
    },

    onSuccessConnectAccount() {
      this.userAppStatus = 'PLAN_NOT_SELECTED';
    },

    loadLanguages() {
      this.$api.get('/languages').then((result) => {
        console.log(result.data.data)
        this.languages = result.data.data;
      }).catch(e => {
        alert(e.message)
        this.error = e
      })
    },

    loadDocuments() {
      this.$api.get('/documents?bot_id=1').then(result => {
        this.documents = result.data.data;
      });
    },

    validateEmail(email) {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regex.test(email);
    },

    updateContacts() {
      this.isFormProcessing = true;

      this.emailError = '';
      this.phoneError = '';

      if (!this.validateEmail(this.contactForm.email)) {
        this.emailError = this.$t('invalid_email');
        this.isFormProcessing = false;
        return;
      }

      if (!validatePhoneNumber(this.contactForm.phone)) {
        this.isFormProcessing = false;
        return;
      }

      this.$api.post('/user/update-contacts', this.contactForm).then(() => {
        this.userAppStatus = 'EXCHANGE_NOT_CONNECTED';
        this.isFormProcessing = false;
      });
    },

    completeSetup() {
      this.isFormProcessing = true;

      this.$api.post('/user/complete-setup', {}).then(() => {
        window.location.href = '/';
      });
    },

    checkCompleteSetup() {
      this.$api.post('/user/check-complete-setup', {}).then((result) => {
        this.userAppStatus = result.data.status;

        if (this.userAppStatus === 'COMPLETED') {
          window.location.href = '/';
        }
      });
    }
  }
}
</script>

