<template>
  <div class="pt-4 pb-24 px-4">
    <template v-if="loading">
      <div class="bg-white rounded-lg">
        <LoadingBlock />
      </div>
    </template>
    <template v-else>
      <h3 class="text-2xl font-bold mb-5">
        {{ exchangeTitle }}
      </h3>
      <dl class="space-y-6 divide-y divide-gray-900/10 border-b pb-4">
        <Disclosure as="div" class="" v-slot="{ open }">
          <dt>
            <DisclosureButton class="flex w-full items-start justify-between text-left text-gray-900">
              <span class="text-base font-semibold leading-7">{{ $t('api_settings') }}</span>
              <span class="ml-6 flex h-7 items-center">
                  <PlusSmallIcon v-if="!open" class="h-6 w-6" aria-hidden="true" />
                  <MinusSmallIcon v-else class="h-6 w-6" aria-hidden="true" />
                </span>
            </DisclosureButton>
          </dt>
          <DisclosurePanel as="dd" class="mt-2">
            <div class="mt-4">
              <div class="relative">
                <div class="rounded-md bg-blue-50 p-2 mb-2 relative">
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <InformationCircleIcon class="h-5 w-5 text-blue-400" aria-hidden="true" />
                    </div>
                    <div class="ml-3 flex-1 md:flex md:justify-between">
                      <p class="text-sm text-blue-700">{{ $t('add_ip_addresses') }}</p>
                    </div>
                  </div>
                </div>
                <div class="relative">
                  <input
                      readonly
                      v-model="allowedIPs"
                      type="text"
                      id="ip_address"
                      ref="ipAddressInput"
                      class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  />
                  <button class="copy-button absolute right-2 top-1 text-blue-600" @click="copyToClipboard">{{ $t('copy') }}</button>
                </div>
              </div>
              <div class="mt-4">
                <label for="api_key" class="block text-sm font-medium leading-6 text-gray-900">API Key</label>
                <div class="mt-2">
                  <input v-model="form.api_key" type="text" id="api_key" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" />
                </div>
              </div>
              <div class="mt-4">
                <label for="api_secret" class="block text-sm font-medium leading-6 text-gray-900">API Secret</label>
                <div class="mt-2">
                  <input v-model="form.api_secret" type="text" id="api_secret" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" />
                </div>
              </div>
              <div class="mt-4">
                <label for="api_secret" class="block text-sm font-medium leading-6 text-gray-900">
                  {{ $t('api_passphrase') }}
                </label>
                <div class="mt-2">
                  <input v-model="form.api_passphrase" type="text" id="api_secret" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" />
                </div>
              </div>
            </div>
          </DisclosurePanel>
        </Disclosure>
      </dl>
      <dl class="space-y-6 divide-y divide-gray-900/10 pt-4 border-b pb-4">
        <Disclosure as="div" v-slot="{ open }">
          <dt>
            <DisclosureButton class="flex w-full items-start justify-between text-left text-gray-900">
              <span class="text-base font-semibold leading-7">{{ $t('trade_settings') }}</span>
              <span class="ml-6 flex h-7 items-center">
                  <PlusSmallIcon v-if="!open" class="h-6 w-6" aria-hidden="true" />
                  <MinusSmallIcon v-else class="h-6 w-6" aria-hidden="true" />
                </span>
            </DisclosureButton>
          </dt>
          <DisclosurePanel as="dd" class="mt-2">
            <template v-if="tariff">
              <div class="mt-4">
                <label for="side_mode" class="block text-sm font-medium leading-6 text-gray-900">{{ $t('side_mode') }}</label>
                <div class="mt-2">
                  <select v-model="form.side_mode" id="side_mode" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-xs sm:text-sm sm:leading-6">
                    <option value="1">{{ $t('open_long_and_short') }}</option>
                    <option value="2">{{ $t('open_only_long') }}</option>
                    <option value="3">{{ $t('open_only_short') }}</option>
                  </select>
                </div>
              </div>

              <div class="mt-4">
                <label for="initial_margin_type" class="block text-sm font-medium leading-6 text-gray-900">{{ $t('margin_level_type') }}</label>
                <div class="mt-2">
                  <select
                      v-model="form.initial_margin_type"
                      id="initial_margin_type"
                      class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      @change="onChangeInitMargin"
                  >
                    <option value="percent">{{ $t('percent') }}</option>
                    <option value="amount">{{ $t('fix_amount') }}</option>
                  </select>
                </div>
              </div>

              <div class="mt-4 relative mb-4">
                <label for="default-range2" class="flex items-center mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  {{ $t('margin_level_open_trade') }}
                  <input v-model="form.initial_margin" type="number" class="block ml-2 w-24 rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600">
                </label>
                <input
                    v-model="form.initial_margin"
                    id="default-range"
                    type="range"
                    min="1"
                    :max="form.initial_margin_type === 'percent' ? 100 : tariff.balance_to"
                    step="1"
                    class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                    @change="onChangeInitMargin"
                >
                <span class="text-sm text-gray-500 dark:text-gray-400 absolute start-0 -bottom-6">
                <template v-if="form.initial_margin_type === 'percent'">
                  Min 1%
                </template>
                <template v-else>
                  Min 1 USD
                </template>
              </span>
                <span class="text-sm text-gray-500 dark:text-gray-400 absolute start-1/2 -translate-x-1/2 rtl:translate-x-1/2 -bottom-6">
                <template v-if="form.initial_margin_type === 'percent'">
                  {{ form.initial_margin }}%
                </template>
                <template v-else>
                  {{ form.initial_margin }} USD
                </template>
              </span>
                <span class="text-sm text-gray-500 dark:text-gray-400 absolute end-0 -bottom-6">
                <template v-if="form.initial_margin_type === 'percent'">
                  Max 100%
                </template>
                <template v-else>
                  Max {{ tariff.balance_to }} USD
                </template>
              </span>
              </div>

              <div class="mt-10">
                <label for="max_positions" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  {{ $t('max_open_positions') }}
                  <div>
                  <span class="inline-flex items-center text-xs font-medium text-blue-700">
                  {{ $t('allowed_in_tariff_to') }} {{ tariff && tariff.max_positions }}
                </span>
                  </div>
                </label>
                <div class="relative flex items-center max-w-[8rem]">
                  <button @click="onChangeMaxPos('minus')" type="button" class="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-s-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none">
                    <svg class="w-3 h-3 text-gray-900 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 2">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h16"/>
                    </svg>
                  </button>
                  <input v-model="form.max_positions" type="text" id="max_positions" class="bg-gray-50 border-x-0 border-gray-300 h-11 text-center text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                  <button
                      @click="onChangeMaxPos('plus')"
                      type="button"
                      class="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-e-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none"
                      :class="[tariff.max_positions <= form.max_positions ? 'pointer-events-none' : '']"
                  >
                    <svg
                        class="w-3 h-3 text-gray-900 dark:text-white"
                        :class="[tariff.max_positions <= form.max_positions ? 'opacity-50' : '']"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 18 18"
                    >
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 1v16M1 9h16"/>
                    </svg>
                  </button>
                </div>
              </div>

              <div class="mt-4">
                <label for="max_value" class="block text-sm font-medium leading-6 text-gray-900">
                  {{ $t('maximum_amount_in_order') }}
                </label>
                <div class="mt-2">
                  <input v-model="form.max_value" type="number" id="max_value" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" />
                </div>
              </div>

              <div class="mt-4 relative mb-4">
                <label for="default-range2" class="flex items-center mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  {{ $t('max_leverage') }}
                  <input v-model="form.leverage" type="number" class="block ml-2 w-24 rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600">
                </label>
                <input
                    v-model="form.leverage"
                    id="default-range2"
                    type="range"
                    min="1"
                    max="100"
                    step="1"
                    class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                >
                <span class="text-sm text-gray-500 dark:text-gray-400 absolute start-0 -bottom-6">Min x1</span>
                <span class="text-sm text-gray-500 dark:text-gray-400 absolute start-1/2 -translate-x-1/2 rtl:translate-x-1/2 -bottom-6">
                x{{ form.leverage }}
              </span>
                <span class="text-sm text-gray-500 dark:text-gray-400 absolute end-0 -bottom-6">Max x100</span>
              </div>

              <div class="mt-12">
                <SwitchGroup as="div" class="flex items-center justify-between">
                <span class="flex flex-grow flex-col">
                  <SwitchLabel as="span" class="text-sm font-medium leading-6 text-gray-900" passive>{{ $t('status') }}</SwitchLabel>
                  <SwitchDescription as="span" class="text-xs text-gray-500">
                    {{ $t('if_you_turn_off_open') }}
                  </SwitchDescription>
                </span>
                  <Switch v-model="form.status" :class="[form.status ? 'bg-blue-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2']">
                    <span aria-hidden="true" :class="[form.status ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
                  </Switch>
                </SwitchGroup>
              </div>
            </template>
            <template v-else>
              <div class="rounded-xl bg-yellow-100 p-4">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <InformationCircleIcon class="h-5 w-5 text-yellow-600" aria-hidden="true" />
                  </div>
                  <div class="ml-3 flex-1 md:flex md:justify-between">
                    <p class="text-sm text-yellow-700">
                      {{ $t('account_settings_not_available') }}
                    </p>
                  </div>
                </div>
              </div>
            </template>
          </DisclosurePanel>
        </Disclosure>
      </dl>
      <div>
        <div class="mt-5">
          <button
              @click="updateExchange"
              type="button"
              class="flex w-full items-center justify-center rounded-xl bg-blue-600 px-3 py-3 font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              :class="[formProcessing ? 'opacity-50 pointer-events-none' : '']"
          >
            <template v-if="formProcessing">
              <ButtonSpinner />
            </template>
            {{ $t('update_settings') }}
          </button>
          <div class="mt-2 text-gray-500 text-xs text-center">
            {{ $t('note_update_settings') }}
          </div>
        </div>
        <template v-if="formError.errors">
          <div class="rounded-md bg-red-50 p-4 mt-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
              </div>
              <div class="ml-3">
                <h3 class="text-sm font-medium text-red-800">{{ formError.message }}</h3>
                <div class="mt-2 text-sm text-red-700">
                  <ul role="list" class="list-disc space-y-1 pl-5">
                    <li v-for="(er, k) in formError.errors" :key="k">{{ er[0] }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import LoadingBlock from '@/components/LoadingBlock.vue'
import ButtonSpinner from '@/components/ButtonSpinner.vue'
import {
  InformationCircleIcon,
  XCircleIcon
} from '@heroicons/vue/20/solid'
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Switch,
  SwitchDescription,
  SwitchGroup,
  SwitchLabel
} from '@headlessui/vue'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/vue/24/outline'
import { initFlowbite } from 'flowbite'
import {addBackButton, hideBackButton} from "@/helpers/telegramHelper";
import {capitalizeFirstLetter} from "@/helpers/stringHelper"

export default {
  components: {
    LoadingBlock,
    ButtonSpinner,
    InformationCircleIcon,
    XCircleIcon,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    MinusSmallIcon,
    PlusSmallIcon,
    Switch,
    SwitchDescription,
    SwitchGroup,
    SwitchLabel
  },

  computed: {
    exchangeTitle() {
      if (!this.exchange) {
        return '';
      }

      return capitalizeFirstLetter(this.exchange.exchange_slug);
    },

    tariff() {
      if (!this.$user) {
        return null;
      }

      if (!this.$user.tariff) {
        return null;
      }

      return this.$user.tariff.tariff;
    }
  },

  methods: {
    updateExchange() {
      this.formProcessing = true;

      const id = this.$route.params.id;

      this.$api.post(`/exchanges/${id}`, this.form).then((result) => {
        console.log(result.data)

        if (result.data.status === 1) {
          this.$router.push({
            name: 'setting'
          })
        } else {
          this.formError.message = 'Error connect'
          this.formError.errors = {
            error_connect: [result.data.message]
          }
          this.formProcessing = false;
        }
      }).catch(e => {
        console.log(e.response.data)
        this.formError = e.response.data;
        this.formProcessing = false;
      })
    },

    loadExchange() {
      const id = this.$route.params.id;

      this.$api.get(`/exchanges/${id}`).then((result) => {
        console.log(result.data.data);
        this.exchange = result.data.data

        this.form.type_exchange = this.exchange.exchange_slug;
        this.form.api_key = this.exchange.api_key;
        this.form.api_secret = this.exchange.api_secret;
        this.form.api_passphrase = this.exchange.api_passphrase;
        this.form.initial_margin = this.exchange.initial_margin;
        this.form.initial_margin_type = this.exchange.initial_margin_type;
        this.form.side_mode = this.exchange.side_mode;
        this.form.max_positions = this.exchange.max_positions;
        this.form.leverage = this.exchange.leverage;
        this.form.max_value = this.exchange.max_value;
        this.form.status = this.exchange.status;

        this.loading = false;
      })
    },

    onChangeMaxPos(type) {
      if (type === 'plus' && this.tariff.max_positions > this.form.max_positions) {
        this.form.max_positions = parseInt(this.form.max_positions) + 1;
      }

      if (type === 'minus' && this.form.max_positions >= 2) {
        this.form.max_positions = parseInt(this.form.max_positions) - 1;
      }
    },

    onChangeInitMargin() {
      if (this.form.initial_margin_type === 'percent' && this.form.initial_margin > 100) {
        this.form.initial_margin = 100;
      }
    },

    copyToClipboard() {
      const copyText = this.$refs.ipAddressInput;
      copyText.select();
      document.execCommand('copy');
      alert("Copied the IP address: " + this.allowedIPs);
    }
  },

  data() {
    return {
      loading: true,
      error: null,
      selectedExchange: null,
      allowedIPs: this.$settings.list_ips,
      exchange: null,
      formError: {
        message: '',
        errors: null,
        status: 0,
      },
      form: {
        type_exchange: null,
        api_key: '',
        api_secret: '',
        api_passphrase: '',
        initial_margin_type: '',
        initial_margin: 1,
        side_mode: 0,
        max_positions: 0,
        max_value: 0.00,
        leverage: 1,
        status: false,
      },
      formProcessing: false,
      capitalizeFirstLetter
    }
  },

  mounted() {
    initFlowbite();
    this.loadExchange();
    addBackButton(this.$router);

    console.log('tariff', this.$user.tariff.tariff);
  },

  beforeUnmount() {
    hideBackButton();
  }
}
</script>