import { createRouter as createVueRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SettingView from '../views/SettingView.vue'
import TariffView from '../views/TariffView.vue'
import CreateExchangeView from '../views/CreateExchangeView.vue'
import EditExchangeView from '../views/EditExchangeView.vue'
import StatsView from '../views/StatsView.vue'
import LogView from '../views/LogView.vue'
import NotificationsView from '../views/settings/NotificationsView.vue'
import SignalsView from '../views/settings/SignalsView.vue'
import LanguageView from '../views/settings/LanguageView.vue'
import OrdersView from '../views/OrdersView.vue'
import ReferralsView from '../views/ReferralsView.vue'
import FaqView from '../views/FaqView.vue'
import InCompletedUserAccessView from '../views/InCompletedUserAccessView.vue'
import ProfileView from '../views/settings/ProfileView.vue'
import SecurityView from '../views/settings/SecurityView.vue'
import TwoFactorAuthView from '../views/TwoFactorAuthView.vue'
import SignalDetailView from '../views/SignalDetailView.vue'
import TradeSwapView from '../views/exchange/TradeSwapView.vue'
import ReferralsStatsView from '../views/ReferralsStatsView.vue'

export default function createRouter(user = null) {
    const routes = [
        {
            path: '/',
            name: 'home',
            component: HomeView,
            meta: {
                requiresActiveTariff: true
            }
        },
        {
            path: '/orders',
            name: 'orders',
            component: OrdersView,
            meta: {
                requiresActiveTariff: true
            }
        },
        {
            path: '/stats',
            name: 'stats',
            component: StatsView,
            meta: {
                requiresActiveTariff: true
            }
        },
        {
            path: '/ref-stats',
            name: 'ref-stats',
            component: ReferralsStatsView,
            meta: {
                requiresActiveTariff: false
            }
        },
        {
            path: '/refs',
            name: 'refs',
            component: ReferralsView,
        },
        {
            path: '/tariff',
            name: 'tariff',
            component: TariffView,
        },
        {
            path: '/faq',
            name: 'faq',
            component: FaqView
        },
        {
            path: '/setting',
            name: 'setting',
            component: SettingView,
        },
        {
            path: '/setting/notifications',
            name: 'notifications',
            component: NotificationsView,
            meta: {
                requiresActiveTariff: true
            }
        },
        {
            path: '/setting/signals',
            name: 'signals',
            component: SignalsView,
            meta: {
                requiresActiveTariff: true
            }
        },
        {
            path: '/setting/language',
            name: 'language',
            component: LanguageView
        },
        {
            path: '/setting/profile',
            name: 'profile',
            component: ProfileView
        },
        {
            path: '/setting/security',
            name: 'security',
            component: SecurityView
        },
        {
            path: '/create-exchange',
            name: 'create-exchange',
            component: CreateExchangeView
        },
        {
            path: '/exchange/:id',
            name: 'edit-exchange',
            component: EditExchangeView
        },
        {
            path: '/trade-swap/:exchange_id/:ticker',
            name: 'trade-swap',
            component: TradeSwapView
        },
        {
            path: '/incomplete',
            name: 'incomplete',
            component: InCompletedUserAccessView,
        },
        {
            path: '/two-factor-auth',
            name: 'two-factor-auth',
            component: TwoFactorAuthView,
        },
        {
            path: '/signals/:id',
            name: 'show-signal',
            component: SignalDetailView
        },
        {
            path: '/logs',
            name: 'logs',
            component: LogView,
            meta: {
                requiresActiveTariff: true
            }
        },
    ];

    const router = createVueRouter({
        history: createWebHistory(process.env.BASE_URL),
        routes,
        scrollBehavior(to, from, savedPosition) {
            return savedPosition ? savedPosition : { left: 0, top: 0 };
        }
    });

    router.beforeEach((to, from, next) => {
        console.log('Navigating to:', to.name);

        if (user.app_status !== 'COMPLETED') {
            if (to.name !== 'incomplete') {
                next({ name: 'incomplete' });
                return;
            } else {
                next();
                return;
            }
        }

        if (to.matched.some(record => record.meta.requiresActiveTariff)) {
            if (user.tariff && user.tariff.status === 'active') {
                next();
            } else {
                if (to.name !== 'tariff') {
                    next({ name: 'tariff' });
                } else {
                    next();
                }
            }
        } else {
            next();
        }
    });

    return router;
}
